import * as React from "react";

function IconMinus() {
  return (
    <svg
      width={16}
      height={16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 7v2h14V7H1z" />
    </svg>
  );
}

export default IconMinus;
