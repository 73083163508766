import PropTypes from "prop-types";
import cn from "classnames";
import Truncate from "solar/components/Truncate";
import styles from "components/adventure/Text.module.scss";

export default function Text({
  children,
  tag = null,
  mono = false,
  className = null,
  align = "left",
  maxLength = null,
}) {
  const Tag = tag || "div";

  return (
    <Tag
      className={cn(
        styles.component,
        className,
        styles[align],
        mono && styles.mono
      )}
    >
      {maxLength ? (
        <Truncate chars={maxLength} more moreText="show more">
          {children}
        </Truncate>
      ) : (
        children
      )}
    </Tag>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  mono: PropTypes.bool,
  className: PropTypes.node,
  align: PropTypes.string,
  maxLength: PropTypes.number,
};
