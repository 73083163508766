import Image from "next/image";
import Title from "components/adventure/Title";
import Text from "components/adventure/Text";
import Button from "solar/components/Button";
import styles from "components/shared/adventures/PanelSuccess.module.scss";

import photoAvatar from "public/adventures/katie.png";

export default function PanelSuccess() {
  return (
    <div className={styles.component}>
      <Image
        src={photoAvatar}
        alt="Katie from customer success"
        placeholder="blur"
        className={styles.photo}
      />
      <Title size="md" className={styles.title}>
        We&apos;re on it
      </Title>
      <Text size="sm">
        You can also schedule a call below, if you&apos;d like to speak with us
        now.
      </Text>
      <Button
        type="button"
        variant="orange"
        responsive
        className={styles.button}
        onClick={() => {
          window.open(
            "https://calendly.com/katie-thermal/your-surf-adventure",
            "_blank"
          );
        }}
      >
        Let&apos;s talk
      </Button>
    </div>
  );
}
