import * as React from "react";

function IconPlus() {
  return (
    <svg
      width={16}
      height={16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0v7H0v2h7v7h2V9h7V7H9V0H7z" />
    </svg>
  );
}

export default IconPlus;
