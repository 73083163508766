/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";
import cn from "classnames";
import { useState } from "react";
import Link from "next/link";
import styles from "solar/components/Truncate.module.scss";

export default function Truncate({
  children,
  clamp = null,
  chars = null,
  more = false,
  moreText = "read more",
  moreModal = null,
  moreLink = "",
  markdown = false,
}) {
  const [expanded, setExpanded] = useState(false);

  const moreElement = (
    <>
      {moreModal && (
        <span
          className={styles.more}
          onClick={moreModal}
          onKeyPress={moreModal}
          tabIndex="-1"
          role="button"
        >
          {moreText}
        </span>
      )}
      {moreLink && (
        <Link prefetch={false} className={styles.more} href={moreLink}>
          {moreText}
        </Link>
      )}
      {more && !moreLink && !moreModal && (
        <span
          className={styles.more}
          onClick={() => setExpanded(true)}
          onKeyPress={() => setExpanded(true)}
          tabIndex="-1"
          role="button"
        >
          {moreText}
        </span>
      )}
    </>
  );

  return (
    <>
      {markdown && (
        <div className={cn(styles.markdown, expanded && styles.expanded)}>
          {children}
          {moreElement}
        </div>
      )}
      {clamp && (
        <div className={styles.clamp} style={{ WebkitLineClamp: clamp }}>
          {children}
        </div>
      )}

      {!markdown && !clamp && (
        <>
          {chars < children?.length ? (
            <>
              {more ? (
                <>
                  {expanded ? (
                    children
                  ) : (
                    <>
                      {children.substring(0, chars).trim()}
                      {"... "}
                      {moreElement}
                    </>
                  )}
                </>
              ) : (
                <>
                  {children.substring(0, chars).trim()}
                  {"... "}
                </>
              )}
            </>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}

Truncate.propTypes = {
  children: PropTypes.node.isRequired,
  clamp: PropTypes.number,
  chars: PropTypes.number,
  more: PropTypes.bool,
  moreText: PropTypes.string,
  moreModal: PropTypes.func,
  moreLink: PropTypes.string,
  markdown: PropTypes.bool,
};
