import * as React from "react";

function IconCheckFluid() {
  return (
    <svg
      width="100%"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M14.406 3l-.719.688-7.28 7.281-3.282-3.281L2.406 7 1 8.406l.688.719 4 4 .72.688.718-.688 8-8 .688-.719L14.408 3h-.002z" />
    </svg>
  );
}

export default IconCheckFluid;
