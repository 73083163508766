import * as React from "react";

function IconChevronLeftFluid() {
  return (
    <svg
      width="100%"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.414 12l7.293 7.293-1.414 1.414L6.586 12l8.707-8.707 1.414 1.414L9.414 12z"
      />
    </svg>
  );
}

export default IconChevronLeftFluid;
