import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/adventure/Divider.module.scss";

export default function Divider({ light = false }) {
  return <div className={cn(styles.component, light && styles.light)} />;
}

Divider.propTypes = {
  light: PropTypes.bool,
};
