import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconPlus from "solar/icons/IconPlus";
import IconMinus from "solar/icons/IconMinus";
import styles from "components/adventure/NumericInput.module.scss";

export default function NumericInput({
  defaultValue = null,
  updateParentValue,
}) {
  const [inputVal, setInputVal] = useState(defaultValue);
  const maxLength = 2;

  const handleChange = (e) => {
    // strip any non numeric characters from input
    const newVal = e.target.value.replace(/\D/g, "");
    if (newVal.length <= maxLength) {
      setInputVal(newVal);
    }
  };

  const handlePlus = () => {
    const currentVal = parseInt(inputVal, 10);
    if (!inputVal) {
      setInputVal(1);
    } else if (currentVal < 99) {
      setInputVal(currentVal ? currentVal + 1 : 1);
    }
  };

  const handleMinus = () => {
    const currentVal = parseInt(inputVal, 10);
    if (currentVal > 1) {
      setInputVal(currentVal - 1);
    }
  };

  useEffect(() => {
    updateParentValue(inputVal);
  }, [inputVal, updateParentValue]);

  return (
    <div className={styles.component}>
      <button type="button" className={styles.button} onClick={handleMinus}>
        <IconMinus />
      </button>
      <input
        type="text"
        className={styles.input}
        value={inputVal}
        onChange={handleChange}
      />
      <button type="button" className={styles.button} onClick={handlePlus}>
        <IconPlus />
      </button>
    </div>
  );
}

NumericInput.propTypes = {
  defaultValue: PropTypes.number,
  updateParentValue: PropTypes.func,
};
