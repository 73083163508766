import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/adventure/Title.module.scss";

export default function Title({
  children,
  className = null,
  size = "md",
  align = "left",
  sansSerif = false,
}) {
  return (
    <h2
      className={cn(
        styles.component,
        styles[size],
        styles[align],
        className,
        sansSerif && styles.sans
      )}
    >
      {children}
    </h2>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  className: PropTypes.node,
  align: PropTypes.string,
  sansSerif: PropTypes.bool,
};
