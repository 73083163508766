import PropTypes from "prop-types";
import cn from "classnames";
import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import axios from "axios";
import styles from "components/shared/adventures/InputPhone.module.scss";

export default function InputPhone({
  defaultValue,
  updateParentNumber,
  updateParentInvalid,
  isSmall,
  allowBlank = false,
  className = null,
  noBold = false,
  placeholder = "Phone number",
}) {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(false);
  const [defaultPhone, setDefaultPhone] = useState();

  // save default value to state
  useEffect(() => {
    setDefaultPhone(parsePhoneNumber(defaultValue || ""));
  }, [defaultValue]);

  // set default country and number
  useEffect(() => {
    if (defaultPhone) {
      setPhoneCountry(defaultPhone?.country);
      setPhoneNumber(defaultPhone?.number);
    } else {
      // Note: we are using the free plan for ipapi.co which gives us 1000/day api calls.
      // We should keep an eye on this though and upgrade if begin to come close to our limit.
      axios.get("https://ipapi.co/json/").then((response) => {
        setPhoneCountry(response.data.country);
      });
    }
  }, [defaultPhone]);

  // pass number changes up to the parent when necessary
  useEffect(() => {
    if (updateParentNumber) {
      updateParentNumber(phoneNumber);
    }
    setPhoneIsInvalid(false);
  }, [phoneNumber, updateParentNumber]);

  // pass isValid changes up to the parent when necessary
  useEffect(() => {
    if (updateParentInvalid) {
      updateParentInvalid(phoneIsInvalid);
    }
  }, [phoneIsInvalid, updateParentInvalid]);

  function validatePhoneNumber() {
    const bypassCheck = !phoneNumber && allowBlank;
    if (!bypassCheck && !isPossiblePhoneNumber(phoneNumber || "")) {
      setPhoneIsInvalid(true);
    }
  }

  return (
    <div
      className={cn(
        styles.component,
        isSmall && styles.small,
        noBold && styles["no-bold"]
      )}
    >
      <div
        className={cn(
          styles.input,
          phoneIsInvalid && styles.invalid,
          className
        )}
      >
        <PhoneInput
          defaultCountry={phoneCountry}
          placeholder={placeholder}
          value={phoneNumber}
          onChange={setPhoneNumber}
          onBlur={validatePhoneNumber}
          className={styles.phone}
          initialValueFormat="national"
        />
      </div>
    </div>
  );
}

InputPhone.propTypes = {
  defaultValue: PropTypes.string,
  updateParentNumber: PropTypes.func,
  updateParentInvalid: PropTypes.func,
  isSmall: PropTypes.bool,
  allowBlank: PropTypes.bool,
  className: PropTypes.node,
  noBold: PropTypes.bool,
  placeholder: PropTypes.string,
};
