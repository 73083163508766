/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { useId } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useSelect } from "downshift";
import IconChevronLeftFluid from "solar/icons/IconChevronLeftFluid";
import IconCheckFluid from "solar/icons/IconCheckFluid";
import styles from "components/shared/adventures/Select.module.scss";

export default function Select({
  options,
  selectedOption,
  handleChange,
  inputLabel = null,
  placeholder = null,
}) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    initialSelectedItem: selectedOption,
    onSelectedItemChange: (changes) => {
      if (changes && changes.selectedItem) {
        handleChange(changes.selectedItem);
      }
    },
  });

  const downshiftToggleId = useId();
  const downshiftMenuId = useId();
  return (
    <div>
      <div className={styles.wrapper}>
        {inputLabel && <label {...getLabelProps()}>{inputLabel}</label>}
        <div
          className={styles.toggle}
          {...getToggleButtonProps()}
          id={`downshift-${downshiftToggleId}-toggle-button`}
          aria-labelledby={`downshift-${downshiftToggleId}-label downshift-${downshiftToggleId}-toggle-button`}
        >
          <span className={styles.text}>{selectedItem || placeholder}</span>
          <span
            className={cn(styles.arrow, isOpen ? styles.open : styles.closed)}
          >
            <IconChevronLeftFluid />
          </span>
        </div>
      </div>
      <div className={styles.list}>
        <ul
          className={cn(styles.menu, !isOpen && styles.hidden)}
          {...getMenuProps()}
          id={`downshift-${downshiftMenuId}-menu`}
          aria-labelledby={`downshift-${downshiftMenuId}-label`}
        >
          {isOpen &&
            options.map((item, index) => (
              <li
                className={cn(
                  styles.item,
                  highlightedIndex === index && styles.highlighted,
                  selectedItem === item && styles.selected
                )}
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                <span>{item}</span>
                {selectedItem === item && (
                  <span className={styles.checkmark}>
                    <IconCheckFluid />
                  </span>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string,
  handleChange: PropTypes.func,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
};
