import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import add from "date-fns/add";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import format from "date-fns/format";
import { createAdventureRequest } from "lib/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextInputWithLabel from "solar/components/TextInputWithLabel";
import TextInput from "solar/components/TextInput";
import FormNote from "solar/components/FormNote";
import Button from "solar/components/Button";
import Alert from "solar/components/Alert";
import Title from "components/adventure/Title";
import Text from "components/adventure/Text";
import Divider from "components/adventure/Divider";
import Select from "components/shared/adventures/Select";
import NumericInput from "components/adventure/NumericInput";
// import SurfTypeSelect from "components/adventure/SurfTypeSelect";
import InputPhone from "components/shared/adventures/InputPhone";
import styles from "components/shared/adventures/PanelForm.module.scss";
import { sendAdventureRequestEvent } from "lib/analytics";

export default function PanelForm({
  updatePanelState,
  onSubmitCallback = null,
  formSource = null,
  adventures,
}) {
  const router = useRouter();
  // eslint-disable-next-line camelcase
  const { utm_campaign, utm_content, utm_medium, utm_source, utm_term } =
    router.query;
  const startDate = new Date();
  const endDate = add(startDate, { years: 1 });
  const allMonths = eachMonthOfInterval({ start: startDate, end: endDate });
  const monthOptions = allMonths.map((month) => format(month, "MMMM YYY"));
  const adventureOptions = adventures.map((a) => a.destination_name).sort();
  const destinationOptions = ["I'm not sure", ...adventureOptions, "Other"];
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(true);
  const [groupSize, setGroupSize] = useState(2);
  const [errorMessage, setErrorMessage] = useState(null);

  const schema = Yup.object().shape({
    destination_selection: Yup.string().required("Select a destination."),
    travel_month_and_year: Yup.string().required("Select a travel month."),
    num_travelers: Yup.string().required("Select a group size."),
    // desired_surf_type: Yup.string().required("Select your surf preference."),
    first_name: Yup.string().required("Your first name is required."),
    last_name: Yup.string().required("Your last name is required."),
    email: Yup.string()
      .required("Your email is required.")
      .email("A valid email is required."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("form_source", formSource);
  }, [setValue, formSource]);

  useEffect(() => {
    setValue("num_travelers", groupSize);
  }, [groupSize, setValue]);

  useEffect(() => {
    setValue("utm_campaign", utm_campaign);
    setValue("utm_content", utm_content);
    setValue("utm_medium", utm_medium);
    setValue("utm_source", utm_source);
    setValue("utm_term", utm_term);
    // eslint-disable-next-line camelcase
  }, [setValue, utm_campaign, utm_content, utm_medium, utm_source, utm_term]);

  async function submitForm(data) {
    const response = await createAdventureRequest(data);
    if (response.errorMsg) {
      // error
      setErrorMessage(response.errorMsg);
    } else {
      // success
      if (onSubmitCallback) {
        onSubmitCallback();
      }
      updatePanelState("formSuccess");
      sendAdventureRequestEvent();
    }
  }

  // force form state to update for autofilled values
  const updateField = (name, val) => {
    setValue(name, val);
  };

  function handleDepartingChange(val) {
    setValue("travel_month_and_year", val);
    setSelectedItem(val);
  }

  function handleDestinationChange(val) {
    setValue("destination_selection", val);
    setSelectedDestination(val);
  }

  function handlePhoneInvalid(val) {
    setPhoneIsInvalid(val);
  }

  useEffect(() => {
    setValue("phone_number", phoneNumber);
  }, [phoneNumber, setValue]);

  return (
    <div className={styles.component}>
      <Title size="md" className={styles.title}>
        Your adventure
      </Title>
      <Text size="sm">
        Tell us more about the adventure you seek, and we&apos;ll be in touch
        within a day with ideas for your trip.
      </Text>
      <Divider />
      <form onSubmit={handleSubmit(submitForm)} noValidate>
        <TextInput
          id="form_source"
          name="form_source"
          type="hidden"
          register={register}
        />
        <div className={styles.label}>Have a spot in mind?</div>
        <Select
          options={destinationOptions}
          selectedOption={selectedDestination}
          handleChange={handleDestinationChange}
          placeholder="Select a destination"
        />
        <TextInput
          id="destination_selection"
          name="destination_selection"
          type="hidden"
          register={register}
        />
        {errors.destination_selection && (
          <FormNote hasError>{errors.destination_selection.message}</FormNote>
        )}

        <div className={styles.label}>When would you like to go?</div>
        <Select
          options={monthOptions}
          selectedOption={selectedItem}
          handleChange={handleDepartingChange}
          placeholder="Select a month"
        />
        <TextInput
          id="travel_month_and_year"
          name="travel_month_and_year"
          type="hidden"
          register={register}
        />
        {errors.travel_month_and_year && (
          <FormNote hasError>{errors.travel_month_and_year.message}</FormNote>
        )}

        <div className={styles.label}>How many people are traveling?</div>
        <NumericInput
          name="num_travelers"
          register={register}
          defaultValue={2}
          updateParentValue={setGroupSize}
        />
        <TextInput
          id="num_travelers"
          name="num_travelers"
          type="hidden"
          register={register}
        />
        {errors.num_travelers && (
          <FormNote hasError>{errors.num_travelers.message}</FormNote>
        )}

        {/* <div className={styles.label}>What kinda surf are you looking for?</div>
        <SurfTypeSelect register={register} setValue={setValue} />
        {errors.desired_surf_type && (
          <FormNote hasError>{errors.desired_surf_type.message}</FormNote>
        )} */}

        <div className={styles.label}>Where can we reach you?</div>
        <TextInputWithLabel
          id="first_name"
          name="first_name"
          type="text"
          register={register}
          labelText="First name"
          hasError={!!errors.first_name}
          block
          pairTop
          onChange={(e) => updateField("first_name", e.target.value)}
        />
        <TextInputWithLabel
          id="last_name"
          name="last_name"
          type="text"
          register={register}
          labelText="Last name"
          hasError={!!errors.last_name}
          block
          pairBottom
          onChange={(e) => updateField("last_name", e.target.value)}
        />
        <TextInputWithLabel
          id="email"
          name="email"
          type="email"
          register={register}
          labelText="Email"
          hasError={!!errors.email}
          block
          onChange={(e) => updateField("email", e.target.value)}
          className={styles.email}
        />
        {errors.first_name && (
          <FormNote hasError>{errors.first_name.message}</FormNote>
        )}
        {errors.last_name && (
          <FormNote hasError>{errors.last_name.message}</FormNote>
        )}
        {errors.email && (
          <FormNote hasError={!!errors.email}>{errors.email?.message}</FormNote>
        )}
        <InputPhone
          allowBlank
          className={styles.phone}
          noBold
          updateParentNumber={setPhoneNumber}
          updateParentInvalid={handlePhoneInvalid}
          placeholder="Phone number (optional)"
        />
        <TextInput
          id="phone_number"
          name="phone_number"
          type="hidden"
          register={register}
        />
        {phoneIsInvalid && (
          <FormNote hasError>Please enter a valid phone number.</FormNote>
        )}

        {errorMessage ? (
          <Alert type="warning" title="Let's try that again">
            {errorMessage}
          </Alert>
        ) : (
          <Divider />
        )}
        <Button
          size={3}
          type="submit"
          isSubmitting={isSubmitting}
          block
          variant="orange"
          className={styles.action}
        >
          Send now
        </Button>
        <div className={styles.expect}>
          Expect to hear from us within a day.
        </div>
        <TextInput
          id="utm_campaign"
          name="utm_campaign"
          type="hidden"
          register={register}
        />
        <TextInput
          id="utm_content"
          name="utm_content"
          type="hidden"
          register={register}
        />
        <TextInput
          id="utm_medium"
          name="utm_medium"
          type="hidden"
          register={register}
        />
        <TextInput
          id="utm_source"
          name="utm_source"
          type="hidden"
          register={register}
        />
        <TextInput
          id="utm_term"
          name="utm_term"
          type="hidden"
          register={register}
        />
      </form>
    </div>
  );
}

PanelForm.propTypes = {
  updatePanelState: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func,
  formSource: PropTypes.string,
  adventures: PropTypes.arrayOf(
    PropTypes.shape({
      destination_name: PropTypes.string,
    })
  ).isRequired,
};
